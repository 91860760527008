import Cookies from "js-cookie";
import { DEFAULT_LAT, DEFAULT_LNG } from "../../shared/constant";

// export const getLocationGeometryData=(placeId,)=>{

//   return async(dispatch)=>{
//       try{
//           dispatch({type:"GET_GEOMETRYDATA_REQUEST"});

//           // let instance2 = axios.create();
//           // delete instance2.defaults.headers.common['x-access-token'];

//           // let dataURL=`https://nameless-eyrie-30558.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=geometry&key=${API_KEY}`
//           // let response = await instance2.get(dataURL );
//           const geocoder = new window.google.maps.Geocoder();
//           geocoder.geocode({ placeId: placeId }, (results, status) => {
//             if (status === "OK") {
//               if (results[0]) {
//                 dispatch({type:"GET_GEOMETRYDATA_SUCCESS",payload:results[0]&&results[0].geometry.location});
//                 Cookies.set('lat', results[0]&&results[0].geometry.location.lat() );
//                 Cookies.set('lng', results[0]&&results[0].geometry.location.lng() );

//                 // console.log("results[0]",results[0],results[0].geometry.location.lat(),results[0].geometry.location.lng())
//                 // map.setZoom(11);
//                 // map.setCenter(results[0].geometry.location);
//                 // const marker = new google.maps.Marker({
//                 //   map,
//                 //   position: results[0].geometry.location,
//                 // });
//                 // infowindow.setContent(results[0].formatted_address);
//                 // infowindow.open(map, marker);
//               } else {
//                 //window.alert("No results found");
//               }
//             } else {
//               //window.alert("Geocoder failed due to: " + status);
//             }
//           });

//           // dispatch({type:"GET_GEOMETRYDATA_SUCCESS",payload:results[0]&&results[0].geometry.location});
//       }

//       catch(error){
//           dispatch({type:"GET_GEOMETRYDATA_FAILURE",payload:error});

//       }
//   }
// };

// export const getLocationGeometryData=(placeId)=>{
//   return async(dispatch)=>{
//       try{
//           dispatch({type:"GET_GEOMETRYDATA_REQUEST"});

//           let instance2 = axios.create();
//           delete instance2.defaults.headers.common['x-access-token'];

//           let dataURL=`https://nameless-eyrie-30558.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=geometry&key=${API_KEY}`
//           let response = await instance2.get(dataURL );
//           dispatch({type:"GET_GEOMETRYDATA_SUCCESS",payload:response.data});
//       }

//       catch(error){
//           dispatch({type:"GET_GEOMETRYDATA_FAILURE",payload:error});

//       }
//   }
// };

export const getLocationGeometryData = (coordinates) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_GEOMETRYDATA_REQUEST" });

      await dispatch({
        type: "GET_GEOMETRYDATA_SUCCESS",
        payload: coordinates,
      });

      Cookies.set("lat", coordinates.lat);
      Cookies.set("lng", coordinates.lng);
    } catch (error) {
      dispatch({ type: "GET_GEOMETRYDATA_FAILURE", payload: error });
    }
  };
};

export const getOveralLocationData = (value) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_OVERALLOCATIONDATA_REQUEST" });
      if (value.lat && value.lng) {
        await dispatch({
          type: "GET_OVERALLOCATIONDATA_SUCCESS",
          payload: value,
        });
      } else {
        await dispatch({
          type: "GET_OVERALLOCATIONDATA_SUCCESS",
          payload: { lat: DEFAULT_LAT, lng: DEFAULT_LNG },
        });
      }
    } catch (error) {
      dispatch({ type: "GET_OVERALLOCATIONDATA_FAILURE" });
    }
  };
};

export const locationLoader = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_LOCATIONLOADER_REQUEST" });
      await dispatch({ type: "GET_LOCATIONLOADER_SUCCESS", payload: data });
    } catch (error) {
      dispatch({ type: "GET_LOCATIONLOADER_FAILURE", payload: error });
    }
  };
};
