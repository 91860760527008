import { DEFAULT_LAT, DEFAULT_LNG } from "../../shared/constant";

const initialState = {
  isLoading: false,
  locationLoading: false,
  errorMessage: "",
  location_data: {
    lat: "",
    lng: "",
  },
  overalLocation: null,
  myLoading: false,
  myData: null,
};

const googleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_GEOMETRYDATA_REQUEST":
      return {
        ...state,
        locationLoading: true,
      };

    case "GET_GEOMETRYDATA_SUCCESS":
      return {
        ...state,
        locationLoading: false,
        location_data: payload,
        location_data: {
          ...state.location_data,
          lat: payload.lat,
          lng: payload.lng,
        },
      };

    case "GET_GEOMETRYDATA_FAILURE":
      return {
        ...state,
        locationLoading: false,
        errorMessage: payload,
      };

    case "GET_OVERALLOCATIONDATA_REQUEST":
      return {
        ...state,
        isLoading: true,
      };

    case "GET_OVERALLOCATIONDATA_SUCCESS":
      return {
        ...state,
        isLoading: false,
        overalLocation: payload,
      };
    case "GET_OVERALLOCATIONDATA_FAILURE":
      return {
        ...state,
        isLoading: false,
        overalLocation: { lat: DEFAULT_LAT, lng: DEFAULT_LNG },
      };

    case "GET_LOCATIONLOADER_REQUEST":
      return {
        ...state,
        myLoading: true,
      };
    case "GET_LOCATIONLOADER_SUCCESS":
      return {
        ...state,
        myLoading: false,
        myData: payload,
      };
    case "GET_LOCATIONLOADER_FAILURE":
      return {
        ...state,
        myLoading: false,
      };

    default:
      return state;
  }
};

export default googleReducer;
