import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { DEFAULT_LAT, DEFAULT_LNG } from "./shared/constant";
import { getOveralLocationData } from "./redux/actions/googleAction";

const MyLocationData = () => {
  const dispatch = useDispatch();

  //Accessing location_data from redux store Start
  const myCordinates = useSelector((state) => {
    return state.googledata;
  });
  let { location_data } = myCordinates;
  //Accessing location_data from redux store Ends

  //Handeling Change in Cookies latitude and Longitude Start
  const [cookiesLocation, setCookiesLocation] = useState({
    lat: "",
    lng: "",
  });
  useEffect(() => {
    setCookiesLocation({
      ...cookiesLocation,
      lat: Cookies.get("lat") ? Cookies.get("lat") : DEFAULT_LAT,
      lng: Cookies.get("lng") ? Cookies.get("lng") : DEFAULT_LNG,
    });
    // eslint-disable-next-line
  }, [Cookies.get("lat"), Cookies.get("lng")]);
  //Handeling Change in Cookies latitude and Longitude End

  //Assigning the latest value to myLocation depending on change in cookiesLocation and location_data Start
  const [myLocation, setMyLocation] = useState({
    lat: "",
    lng: "",
  });
  useEffect(() => {
    if (
      (myLocation.lat !== location_data.lat &&
        myLocation.lng !== location_data.lng) ||
      (myLocation.lat !== cookiesLocation.lat &&
        myLocation.lng !== cookiesLocation.lng) ||
      (cookiesLocation.lat !== location_data.lat &&
        cookiesLocation.lng !== location_data.lng)
    ) {
      setMyLocation({
        ...myLocation,
        lat:
          location_data && location_data.lat
            ? location_data.lat
            : cookiesLocation.lat
            ? cookiesLocation.lat
            : DEFAULT_LAT,
        lng:
          location_data && location_data.lng
            ? location_data.lng
            : cookiesLocation.lng
            ? cookiesLocation.lng
            : DEFAULT_LNG,
      });
    }
    // eslint-disable-next-line
  }, [location_data, cookiesLocation]);

  //Assigning the latest value to myLocation depending on change in cookiesLocation and location_data Ends

  //Assigning the latest value to myLocation2 depending on change in myLocation Start
  const [myLocation2, setMyLocation2] = useState({
    lat: "",
    lng: "",
  });

  useEffect(() => {
    if (
      myLocation2.lat !== myLocation.lat &&
      myLocation2.lng !== myLocation.lng
    ) {
      setMyLocation2({
        ...myLocation2,
        lat: myLocation && myLocation.lat,
        lng: myLocation && myLocation.lng,
      });
    }
    // eslint-disable-next-line
  }, [myLocation]);
  //Assigning the latest value to myLocation2 depending on change in myLocation Ends

  useEffect(() => {
    if (myLocation2.lat !== "" && myLocation2.lng !== "") {
      if (
        myLocation2.lat === myLocation.lat &&
        myLocation2.lng === myLocation.lng
      ) {
        dispatch(getOveralLocationData(myLocation2));
      }
    }
    // eslint-disable-next-line
  }, [myLocation2]);

  return <React.Fragment></React.Fragment>;
};

export default MyLocationData;
