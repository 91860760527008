

const initialState = {
    isLoading : false,
    errorMessage:'',
    myLocation_Name:null,
    myLocation_Data:null,
    };
    
    const myLocationDataReducer = (state = initialState, { type, payload }) => {
      switch (type) {

        case "GET_MYLOCATION_NAME":
            return  {
                ...state,
                isLoading: false,
                myLocation_Name :payload
            };

          
        case "GET_MYLOCATION_DATA":
            return  {
                ...state,
                isLoading: false,
                myLocation_Data :payload
            };

       
        default:
          return state;
      }
    };
    
    export default myLocationDataReducer;
    