const initialState = {
  isLoading: false,
  errorMessage: "",
  favouriteDishDetail_Data: null,
  whatsNewDish_List: null,
  breakfastMenuDish_List: null,
  lunchMenuDish_List: null,
  dinnerMenuDish_List: null,
  dessertMenuDish_List: null,
  buffetMenuDish_List: null,
  drinksMenuDish_List: null,
  nibbleMenuDish_List: null,
  setmenuMenuDish_List: null,
  subscribedDishDetail_Data: null,

  fourMoreFavouriteDishLoading: false,
  fourFavouriteDishListData: [],

  fourMoreWhatsNewDishLoading: false,
  fourWhatsNewDishListData: [],

  fourMoreBreakfastDishLoading: false,
  fourBreakfastDishListData: [],

  fourMoreLunchMenuDishLoading: false,
  fourLunchMenuDishListData: [],

  fourMoreDinnerMenuDishLoading: false,
  fourDinnerMenuDishListData: [],

  fourMoreDessertMenuDishLoading: false,
  fourDessertMenuDishListData: [],

  fourMoreBuffetMenuDishLoading: false,
  fourBuffetMenuDishListData: [],

  fourMoreDrinksMenuDishLoading: false,
  fourDrinksMenuDishListData: [],

  fourMoreNibbleMenuDishLoading: false,
  fourNibbleMenuDishListData: [],

  fourMoreSetmenuMenuDishLoading: false,
  fourSetmenuMenuDishListData: [],
};

const dishListPageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    //getSelectedRestaurantDetailInfoData
    case "GET_SUBSCRIBEDDISHLISTDATA_REQUEST":
    case "GET_FAVOURITEDISHLISTDATA_REQUEST":
    case "GET_WHATSNEWDISHLIST_REQUEST":
    case "GET_BREAKFASTMENUDISHLIST_REQUEST":
    case "GET_LUNCHTMENUDISHLIST_REQUEST":
    case "GET_DINNERMENUDISHLIST_REQUEST":
    case "GET_DESSERTMENUDISHLIST_REQUEST":
    case "GET_BUFFETMENUDISHLIST_REQUEST":
    case "GET_DRINKSMENUDISHLIST_REQUEST":
    case "GET_NIBBLEMENUDISHLIST_REQUEST":
    case "GET_SETMENUMENUDISHLIST_REQUEST":
      return {
        ...state,
        isLoading: true,
        fourFavouriteDishListData: [],
        fourWhatsNewDishListData: [],
        ourBreakfastDishListData: [],
        fourLunchMenuDishListData: [],
        fourDinnerMenuDishListData: [],
        fourDessertMenuDishListData: [],
        fourBuffetMenuDishListData: [],
        fourDrinksMenuDishListData: [],
        fourNibbleMenuDishListData: [],
        fourSetmenuMenuDishListData: [],
      };

    case "GET_SUBSCRIBEDDISHLISTDATA_SUCCESS":
      return {
        ...state,
        isLoading: false,
        subscribedDishDetail_Data: payload,
      };

    case "GET_FAVOURITEDISHLISTDATA_SUCCESS":
      return {
        ...state,
        isLoading: false,
        favouriteDishDetail_Data: payload,
      };
    case "GET_WHATSNEWDISHLIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        whatsNewDish_List: payload,
      };
    case "GET_BREAKFASTMENUDISHLIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        breakfastMenuDish_List: payload,
      };
    case "GET_LUNCHTMENUDISHLIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        lunchMenuDish_List: payload,
      };
    case "GET_DINNERMENUDISHLIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        dinnerMenuDish_List: payload,
      };
    case "GET_DESSERTMENUDISHLIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        dessertMenuDish_List: payload,
      };
    case "GET_BUFFETMENUDISHLIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        buffetMenuDish_List: payload,
      };
    case "GET_DRINKSMENUDISHLIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        drinksMenuDish_List: payload,
      };
    case "GET_NIBBLEMENUDISHLIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        nibbleMenuDish_List: payload,
      };
    case "GET_SETMENUMENUDISHLIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        setmenuMenuDish_List: payload,
      };

    case "GET_SUBSCRIBEDDISHLISTDATA_FAILURE":
    case "GET_FAVOURITEDISHLISTDATA_FAILURE":
    case "GET_WHATSNEWDISHLIST_FAILURE":
    case "GET_BREAKFASTMENUDISHLIST_FAILURE":
    case "GET_LUNCHTMENUDISHLIST_FAILURE":
    case "GET_DINNERMENUDISHLIST_FAILURE":
    case "GET_DESSERTMENUDISHLIST_FAILURE":
    case "GET_BUFFETMENUDISHLIST_FAILURE":
    case "GET_DRINKSMENUDISHLIST_FAILURE":
    case "GET_NIBBLEMENUDISHLIST_FAILURE":
    case "GET_SETMENUMENUDISHLIST_FAILURE":
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };

    //getFourFavouriteDishListData
    case "GET_FOURFAVOURITEDISHLISTDATA_REQUEST":
      return {
        ...state,
        fourMoreFavouriteDishLoading: true,
      };
    case "GET_FOURFAVOURITEDISHLISTDATA_SUCCESS":
      return {
        ...state,
        fourMoreFavouriteDishLoading: false,
        fourFavouriteDishListData: [
          ...state.fourFavouriteDishListData,
          ...payload.data,
        ],
      };

    case "GET_FOURFAVOURITEDISHLISTDATA_FAILURE":
      return {
        ...state,
        fourMoreFavouriteDishLoading: false,
        errorMessage: payload,
      };

    //getFourWhatsNewDishList
    case "GET_FOURWHATSNEWDISHLIST_REQUEST":
      return {
        ...state,
        fourMoreWhatsNewDishLoading: true,
      };
    case "GET_FOURWHATSNEWDISHLIST_SUCCESS":
      return {
        ...state,
        fourMoreWhatsNewDishLoading: false,
        fourWhatsNewDishListData: [
          ...state.fourWhatsNewDishListData,
          ...payload.data,
        ],
      };

    case "GET_FOURWHATSNEWDISHLIST_FAILURE":
      return {
        ...state,
        fourMoreWhatsNewDishLoading: false,
        errorMessage: payload,
      };

    //getFourBreakfastMenuDishList
    case "GET_FOURBREAKFASTMENUDISHLIST_REQUEST":
      return {
        ...state,
        fourMoreBreakfastDishLoading: true,
      };
    case "GET_FOURBREAKFASTMENUDISHLIST_SUCCESS":
      return {
        ...state,
        fourMoreBreakfastDishLoading: false,
        fourBreakfastDishListData: [
          ...state.fourBreakfastDishListData,
          ...payload.data,
        ],
      };

    case "GET_FOURBREAKFASTMENUDISHLIST_FAILURE":
      return {
        ...state,
        fourMoreBreakfastDishLoading: false,
        errorMessage: payload,
      };

    //getFourLunchMenuDishList
    case "GET_FOURLUNCHTMENUDISHLIST_REQUEST":
      return {
        ...state,
        fourMoreLunchMenuDishLoading: true,
      };
    case "GET_FOURLUNCHTMENUDISHLIST_SUCCESS":
      return {
        ...state,
        fourMoreLunchMenuDishLoading: false,
        fourLunchMenuDishListData: [
          ...state.fourLunchMenuDishListData,
          ...payload.data,
        ],
      };

    case "GET_FOURLUNCHTMENUDISHLIST_FAILURE":
      return {
        ...state,
        fourMoreLunchMenuDishLoading: false,
        errorMessage: payload,
      };

    //getFourDinnerMenuDishList
    case "GET_FOURDINNERMENUDISHLIST_REQUEST":
      return {
        ...state,
        fourMoreDinnerMenuDishLoading: true,
      };
    case "GET_FOURDINNERMENUDISHLIST_SUCCESS":
      return {
        ...state,
        fourMoreDinnerMenuDishLoading: false,
        fourDinnerMenuDishListData: [
          ...state.fourDinnerMenuDishListData,
          ...payload.data,
        ],
      };
    case "GET_FOURDINNERMENUDISHLIST_FAILURE":
      return {
        ...state,
        fourMoreDinnerMenuDishLoading: false,
        errorMessage: payload,
      };

    //getFourDessertMenuDishList
    case "GET_FOURDESSERTMENUDISHLIST_REQUEST":
      return {
        ...state,
        fourMoreDessertMenuDishLoading: true,
      };
    case "GET_FOURDESSERTMENUDISHLIST_SUCCESS":
      return {
        ...state,
        fourMoreDessertMenuDishLoading: false,
        fourDessertMenuDishListData: [
          ...state.fourDessertMenuDishListData,
          ...payload.data,
        ],
      };
    case "GET_FOURDESSERTMENUDISHLIST_FAILURE":
      return {
        ...state,
        fourMoreDessertMenuDishLoading: false,
        errorMessage: payload,
      };

    //getFourBuffetMenuDishList
    case "GET_FOURBUFFETMENUDISHLIST_REQUEST":
      return {
        ...state,
        fourMoreBuffetMenuDishLoading: true,
      };
    case "GET_FOURBUFFETMENUDISHLIST_SUCCESS":
      return {
        ...state,
        fourMoreBuffetMenuDishLoading: false,
        fourBuffetMenuDishListData: [
          ...state.fourBuffetMenuDishListData,
          ...payload.data,
        ],
      };
    case "GET_FOURBUFFETMENUDISHLIST_FAILURE":
      return {
        ...state,
        fourMoreBuffetMenuDishLoading: false,
        errorMessage: payload,
      };

    //getFourDrinksMenuDishList
    case "GET_FOURDRINKSMENUDISHLIST_REQUEST":
      return {
        ...state,
        fourMoreDrinksMenuDishLoading: true,
      };
    case "GET_FOURDRINKSMENUDISHLIST_SUCCESS":
      return {
        ...state,
        fourMoreDrinksMenuDishLoading: false,
        fourDrinksMenuDishListData: [
          ...state.fourDrinksMenuDishListData,
          ...payload.data,
        ],
      };
    case "GET_FOURDRINKSMENUDISHLIST_FAILURE":
      return {
        ...state,
        fourMoreDrinksMenuDishLoading: false,
        errorMessage: payload,
      };

    //getFourNibbleMenuDishList
    case "GET_FOURNIBBLEMENUDISHLIST_REQUEST":
      return {
        ...state,
        fourMoreNibbleMenuDishLoading: true,
      };
    case "GET_FOURNIBBLEMENUDISHLIST_SUCCESS":
      return {
        ...state,
        fourMoreNibbleMenuDishLoading: false,
        fourNibbleMenuDishListData: [
          ...state.fourNibbleMenuDishListData,
          ...payload.data,
        ],
      };
    case "GET_FOURNIBBLEMENUDISHLIST_FAILURE":
      return {
        ...state,
        fourMoreNibbleMenuDishLoading: false,
        errorMessage: payload,
      };

    //getFourSetmenuMenuDishList
    case "GET_FOURSETMENUMENUDISHLIST_REQUEST":
      return {
        ...state,
        fourMoreSetmenuMenuDishLoading: true,
      };
    case "GET_FOURSETMENUMENUDISHLIST_SUCCESS":
      return {
        ...state,
        fourMoreSetmenuMenuDishLoading: false,
        fourSetmenuMenuDishListData: [
          ...state.fourSetmenuMenuDishListData,
          ...payload.data,
        ],
      };
    case "GET_FOURSETMENUMENUDISHLIST_FAILURE":
      return {
        ...state,
        fourMoreSetmenuMenuDishLoading: false,
        errorMessage: payload,
      };

    default:
      return state;
  }
};

export default dishListPageReducer;
