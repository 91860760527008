import React, { lazy, Suspense, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import "./App.css";
import { AppStateProvider } from "./context";
import Routes from "./routes";
import MobileRoutes from "./MobileRoutes";
import containers from "./state";
import AlertSystemPage from "./view/AlertSystemPage/AlertSystemPage";
import LoadonTop from "./components/LoadonTop";
import store from "./redux/store";
import { getUserProfileDetail } from "./redux/actions/userProfileAction";
import { useDispatch } from "react-redux";
import ErrorBoundary from "./view/ErrorBoundary";
import CustomLoadingComp from "./components/CustomLoadingComp/CustomLoadingComp";
import { getUserLoginStatusData } from "./redux/actions/generalActions";
import { isMobile } from "react-device-detect";

// const Login = lazy(() => import('./view/Login/Login'));
// const Signup = lazy(() => import('./view/Signup/Signup'));

const ResetPasswordPage = lazy(() =>
  import("./view/ResetPasswordPage/ResetPasswordPage")
);
const EmailVerificationPage = lazy(() =>
  import("./view/EmailVerificationPage/EmailVerificationPage")
);

function App() {
  const dispatch = useDispatch();
  // const token = localStorage.getItem("access_token");
  const history = useHistory();
  useEffect(() => {
    if (localStorage.access_token) {
      store.dispatch(getUserProfileDetail(history));
      store.dispatch(getUserLoginStatusData(true));
    }
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <ErrorBoundary>
      <AppStateProvider containers={containers}>
        <Suspense
          fallback={
            <div>
              <CustomLoadingComp />
            </div>
          }
        >
          <div>
            <AlertSystemPage />
          </div>
          <LoadonTop />

          {isMobile ? (
            <Switch>
              {/* <Route exact path="/reset_password/:token" render={(props) => <ResetPasswordPage {...props} />} />
            <Route exact path="/auth/email_verification/:token" render={(props) => <EmailVerificationPage {...props} />} /> */}
              {/* <Route exact path="/mobile_signup" render={(props) => <SignupMobilePage {...props} />} />
            <Route exact path="/mobile_login" render={(props) => <LogInMobilePage {...props} />} /> */}
              <Route
                exact
                path="/reset_password/:token"
                render={(props) => <ResetPasswordPage {...props} />}
              />
              <Route
                exact
                path="/auth/email_verification/:token"
                render={(props) => <EmailVerificationPage {...props} />}
              />
              <MobileRoutes />
            </Switch>
          ) : (
            <Switch>
              {/* <Route exact path="/signup" render={(props) => <Signup {...props} />} /> */}
              <Route
                exact
                path="/reset_password/:token"
                render={(props) => <ResetPasswordPage {...props} />}
              />
              <Route
                exact
                path="/auth/email_verification/:token"
                render={(props) => <EmailVerificationPage {...props} />}
              />
              <Routes />
            </Switch>
          )}
        </Suspense>
      </AppStateProvider>
    </ErrorBoundary>
  );
}
export default App;

// export default withSplashScreen(App);
