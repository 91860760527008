

const initialState = {
    isLoading : false,
    errorMessage:'',
    mobileHomePageRestaurantNumber:3,
    };
    
    const loadMoreNumberReducer = (state = initialState, { type, payload }) => {
      switch (type) {
        
       
        case "MOBILEHOMEPAGE_RESTAURANT_QUANTITY":
            return {
                ...state,
                isLoading :true,
                mobileHomePageRestaurantNumber:payload,
            };
       
    

        default:
          return state;
      }
    };
    
    export default loadMoreNumberReducer;
    