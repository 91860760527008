import useGlobal from './useGlobal'
// import useStore from './useStore'
// import useCart from './useCart'
// import useAuth from './useAuth'

const containers = {
    useGlobal,
    // useStore,
    // useCart,
    // useAuth,
};

export default containers
