

const initialState = {
    isLoading : false,
    errorMessage:'',
    subscribedRestaurantDetail_Data:null,
    topPicksRestaurant_List:null,
    whatsNewRestaurant_List:null,

    fourMoreTopPicksRestListLoading:false,
    fourTopPicksRestListData:[],

    fourMoreWhatsNewRestListLoading:false,
    fourWhatsNewRestListData:[],
    };
    
    const restaurantListPageReducer = (state = initialState, { type, payload }) => {
      switch (type) {
          
        case "GET_SUBSCRIBEDRESTAURANTDATA_REQUEST":
        case "GET_TOPPICKSRESTAURANTLIST_REQUEST":
        case "GET_WHATSNEWRESTAURANTLIST_REQUEST":
            return {
                ...state,
                isLoading :true,

                fourTopPicksRestListData:[],
                fourWhatsNewRestListData:[],
            };
              
        case "GET_SUBSCRIBEDRESTAURANTDATA_SUCCESS":            
            return{
                ...state,
                isLoading:false,
                subscribedRestaurantDetail_Data: payload
            }  
        case "GET_TOPPICKSRESTAURANTLIST_SUCCESS":            
            return{
                ...state,
                isLoading:false,
                topPicksRestaurant_List: payload
            } 
        case "GET_WHATSNEWRESTAURANTLIST_SUCCESS":            
            return{
                ...state,
                isLoading:false,
                whatsNewRestaurant_List: payload
            }         
        case "GET_SUBSCRIBEDRESTAURANTDATA_FAILURE":  
        case "GET_TOPPICKSRESTAURANTLIST_FAILURE":  
        case "GET_WHATSNEWRESTAURANTLIST_FAILURE":  
            return {
                ...state,
                isLoading:false,
                errorMessage:payload
            };

        //getFourTopPicksRestaurantList
        case "GET_FOURTOPPICKSRESTAURANTLIST_REQUEST":
            return {
                ...state,
                fourMoreTopPicksRestListLoading :true,
            };
        case "GET_FOURTOPPICKSRESTAURANTLIST_SUCCESS":
            return {
                ...state,
                fourMoreTopPicksRestListLoading:false,
                fourTopPicksRestListData:[
                    ...state.fourTopPicksRestListData,
                    ...payload.data
                ],
            };
    
        case "GET_FOURTOPPICKSRESTAURANTLIST_FAILURE":
            return {
                ...state,
                fourMoreTopPicksRestListLoading:false,
                errorMessage:payload
            };    


        //getFourWhatsNewRestaurantList
        case "GET_FOURWHATSNEWRESTAURANTLIST_REQUEST":
            return {
                ...state,
                fourMoreWhatsNewRestListLoading :true,
            };
        case "GET_FOURWHATSNEWRESTAURANTLIST_SUCCESS":
            return {
                ...state,
                fourMoreWhatsNewRestListLoading:false,
                fourWhatsNewRestListData:[
                    ...state.fourWhatsNewRestListData,
                    ...payload.data
                ],
            };
    
        case "GET_FOURWHATSNEWRESTAURANTLIST_FAILURE":
            return {
                ...state,
                fourMoreWhatsNewRestListLoading:false,
                errorMessage:payload
            }; 
        
      
        default:
          return state;
      }
    };
    
    export default restaurantListPageReducer;
    