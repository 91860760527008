

// update product Form
export const updatePreferenceFilter = (key , value) => {
    
    return async(dispatch)=>{
      try{
          await dispatch({type :"UPDATE_PREFERENCEFILTER_FORM" , payload : { key,value} });
      }
      catch(error){
          console.error(error);
      }
  }
};

// update All Preference at a time Form
export const updateAllPreferenceFilter = (data) => {
    
    return async(dispatch)=>{
      try{
          await dispatch({type :"UPDATE_ALLPREFERENCEFILTER_FORM" , payload : data });
      }
      catch(error){
          console.error(error);
      }
  }
};


export const showMypreferenceCheckBoxData = (data) => {
    
    return async(dispatch)=>{
      try{
          await dispatch({type :"GET_SHOWMYPREFERENCECHECKBOX_DATA" , payload : data });
      }
      catch(error){
          console.error(error);
      }
    }
  };