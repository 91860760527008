import axios from "axios";
import Axios from "./axios";
import { setAlert } from "./alertAction";
import { EDAMAM_APP_ID, EDAMAM_APP_KEY } from "../../shared/constant";
import { getUserProfileDetail } from "./userProfileAction";
import { updatePreferenceFilter } from "./globalPreferenceFilterAction";

// register a user
export const registerUser = (user, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "REGISTER_USER_REQUEST" });
      dispatch(registrationSuccess(false));
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let dataURL = `/auth/user_signup`;
      let response = await Axios.post(dataURL, JSON.stringify(user), config);
      dispatch({ type: "REGISTER_USER_SUCCESS", payload: response.data });
      history.push("/");
      // dispatch(setAlert('Registration Successful.Please Verify Mail', 'success'));
      dispatch(showSignUpPopup(false));
      dispatch(registrationSuccess(true));
    } catch (error) {
      dispatch({ type: "REGISTER_USER_FAILURE", payload: error });
      if (error.response) {
        dispatch(setAlert(`${error.response.data.message}`, "error"));
      } else {
        dispatch(setAlert("Something Went Wrong!", "error"));
      }
      dispatch(registrationSuccess(false));
    }
  };
};

export const getLogin = (data, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_LOGIN_REQUEST" });
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let dataURL = `/auth/login`;
      let response = await Axios.post(
        dataURL,
        JSON.stringify({ ...data, role: "user" }),
        config
      );
      dispatch({ type: "GET_LOGIN_SUCCESS", payload: response.data });
      history.push("/");
      dispatch(showSignInPopup(false));
      dispatch(setAlert("You've successfully logged in. Welcome back Picky Pig.", "success"));
      const token = localStorage.getItem("access_token");
      if (token) axios.defaults.headers.common = { "x-access-token": token };
      if (response.data.token) {
        dispatch(getUserProfileDetail(history));
        dispatch(getUserLoginStatusData(true));
      }
    } catch (error) {
      dispatch({ type: "GET_LOGIN_FAILURE", payload: error });
      if (error.response) {
        if (error.response.data.message === "Email is not verified.") {
          dispatch(setAlert("Email is not verified.", "error"));
        } else {
          dispatch(setAlert("Please check your username or password. One of them  isnt correct.", "error"));
        }
      } else {
        dispatch(setAlert("Something Went Wrong!", "error"));
      }
    }
  };
};

export const googleLogin = (data, history, page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GOOGLE_LOGIN_REQUEST" });
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let dataURL = `/auth/google`;
      let response = await Axios.post(dataURL, JSON.stringify(data), config);
      dispatch({ type: "GOOGLE_LOGIN_SUCCESS", payload: response.data });
      if (page === "signIn") {
        dispatch(showSignInPopup(false));
      } else {
        dispatch(showSignUpPopup(false));
      }
      dispatch(setAlert("You've successfully logged in. Welcome back Picky Pig.", "success"));
      const token = localStorage.getItem("access_token");
      if (token) axios.defaults.headers.common = { "x-access-token": token };
      history.push("/");
      if (response.data.token) {
        dispatch(getUserProfileDetail(history));
        dispatch(getUserLoginStatusData(true));
      }
    } catch (error) {
      dispatch({ type: "GOOGLE_LOGIN_FAILURE", payload: error });
      if (error.response) {
        dispatch(setAlert("Wrong Credential", "error"));
      } else {
        dispatch(setAlert("Something Went Wrong!", "error"));
      }
    }
  };
};

export const facebookLogin = (data, history, page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "FACEBOOK_LOGIN_REQUEST" });
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let dataURL = `/auth/facebook`;
      let response = await Axios.post(dataURL, JSON.stringify(data), config);
      dispatch({ type: "FACEBOOK_LOGIN_SUCCESS", payload: response.data });
      history.push("/");
      if (page === "signIn") {
        dispatch(showSignInPopup(false));
      } else {
        dispatch(showSignUpPopup(false));
      }
      dispatch(setAlert("LogIn You've successfully logged in. Welcome back Picky Pig. ", "success"));
      const token = localStorage.getItem("access_token");
      if (token) axios.defaults.headers.common = { "x-access-token": token };
      if (response.data.token) {
        dispatch(getUserProfileDetail(history));
        dispatch(getUserLoginStatusData(true));
      }
    } catch (error) {
      dispatch({ type: "FACEBOOK_LOGIN_FAILURE", payload: error });
      if (error.response) {
        dispatch(setAlert("Wrong Credential", "error"));
      } else {
        dispatch(setAlert("Something Went Wrong!", "error"));
      }
    }
  };
};

export const logoutUser = (history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "LOGOUT_USER_REQUEST" });
      await dispatch({ type: "LOGOUT_USER_SUCCESS" });
      dispatch(setAlert("You've successfully signed out. See you again soon.", "success"));
      history.push("/");
      axios.defaults.headers.common = { "x-access-token": "" };
      dispatch(getUserLoginStatusData(false));
      dispatch(updatePreferenceFilter("allergendata", []));
      dispatch(updatePreferenceFilter("dietarydata", []));
      dispatch(updatePreferenceFilter("lifestyledata", []));
      dispatch(updatePreferenceFilter("featuredata", []));
      dispatch(updatePreferenceFilter("nearby", true));
      dispatch(updatePreferenceFilter("distance", 0));
    } catch (error) {
      console.error(error);
      dispatch({ type: "LOGOUT_USER_FAILURE" });
      if (error.response) {
        dispatch(setAlert(`${error.response.data.message}`, "error"));
      } else {
        dispatch(setAlert("Something Went Wrong!", "error"));
      }
    }
  };
};

export const forgotPassword = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "FORGOT_PASSWORD_REQUEST" });
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let dataURL = `/auth/forgot_password`;
      let response = await Axios.post(
        dataURL,
        JSON.stringify({ ...data, role: "user" }),
        config
      );
      dispatch({ type: "FORGOT_PASSWORD_SUCCESS", payload: response.data });

      if (
        response.data.message === "Reset link was sent to your email address"
      ) {
        dispatch(setAlert(`${response.data.message}`, "success"));
        dispatch(showForgotPasswordPopup(false));
      } else {
        dispatch(setAlert(`${response.data.message}`, "error"));
      }
    } catch (error) {
      dispatch({ type: "FORGOT_PASSWORD_FAILURE", payload: error });
      if (error.response) {
        dispatch(setAlert("Wrong Credential", "error"));
      } else {
        dispatch(setAlert("Something Went Wrong!", "error"));
      }
    }
  };
};

export const resetPassword = (data, history) => {
  // console.log(data);
  return async (dispatch) => {
    try {
      dispatch({ type: "RESET_PASSWORD_REQUEST" });
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let dataURL = `/auth/reset_password`;
      let response = await Axios.post(dataURL, JSON.stringify(data), config);
      dispatch({ type: "RESET_PASSWORD_SUCCESS", payload: response.data });
      dispatch(setAlert(`${response.data.message}`, "success"));
      history.push("/");
    } catch (error) {
      dispatch({ type: "RESET_PASSWORD_FAILURE", payload: error });
      if (error.response) {
        dispatch(setAlert(`${error.response.data.message}`, "error"));
      } else {
        dispatch(setAlert("Something Went Wrong!", "error"));
      }
    }
  };
};

export const sendJoinUsMessage = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "SEND_JOINUS_REQUEST" });
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let dataURL = `/frontend/homePage/join_us`;
      let response = await Axios.post(dataURL, JSON.stringify(data), config);
      dispatch({ type: "SEND_JOINUS_SUCCESS", payload: response.data });
      dispatch(setAlert("Message Send Successfully", "success"));
    } catch (error) {
      dispatch({ type: "SEND_JOINUS_FAILURE", payload: error });
      if (error.response) {
        dispatch(setAlert(`${error.response.data.message}`, "error"));
      } else {
        dispatch(setAlert("Something Went Wrong!", "error"));
      }
    }
  };
};

export const addUpdateRestaurant = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "ADD_UPDATE_RESTAURANT_REQUEST" });
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let dataURL = `/frontend/homePage/contact_update_restaurant`;
      let response = await Axios.post(dataURL, JSON.stringify(data), config);
      dispatch({
        type: "ADD_UPDATE_RESTAURANT_SUCCESS",
        payload: response.data,
      });
      dispatch(setAlert(response.data.message, "success"));
    } catch (error) {
      dispatch({ type: "ADD_UPDATE_RESTAURANT_FAILURE", payload: error });
      if (error.response) {
        dispatch(setAlert(`${error.response.data.message}`, "error"));
      } else {
        dispatch(setAlert("Something Went Wrong!", "error"));
      }
    }
  };
};

export const getNutritionData = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_NUTRITION_DATA_REQUEST" });
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let dataURL = `https://api.edamam.com/api/nutrition-details?app_id=${EDAMAM_APP_ID}&app_key=${EDAMAM_APP_KEY}`;
      let response = await axios.post(dataURL, JSON.stringify(data), config);
      dispatch({ type: "GET_NUTRITION_DATA_SUCCESS", payload: response.data });
    } catch (error) {
      dispatch({ type: "GET_NUTRITION_DATA_FAILURE", payload: error });
    }
  };
};

// export const getRestaurantSearchData = (latitude,longitude,userSearchText) =>  {
//   // console.log("Data: ", latitude,longitude,userSearchText);
//   return async(dispatch)=>{
//     try{

//     let dataURL=`${GOOGLE_PLACE_API_URL}?location=${latitude},${longitude}&sensor=true&type=restaurant&keyword=${userSearchText}&key=${API_KEY}`
//     let response = await axios.get(dataURL );
//     dispatch({ type: "GET_RESTAURANT_SEARCH_DATA", payload: response.data,  });
//       }
//   catch(error){
//     console.log(error);
//       }
//   }
// };

export const showSignUpPopup = (data) => {
  return async (dispatch) => {
    try {
      await dispatch({ type: "SHOW_SIGNUP_POPUP", payload: data });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showSignInPopup = (data) => {
  return async (dispatch) => {
    try {
      await dispatch({ type: "SHOW_LOGIN_POPUP", payload: data });
    } catch (error) {
      console.error(error);
    }
  };
};

export const hideBackContent = (data) => {
  return async (dispatch) => {
    try {
      await dispatch({ type: "HIDE_BACK_CONTENT", payload: data });
    } catch (error) {
      console.error(error);
    }
  };
};

export const registrationSuccess = (data) => {
  return async (dispatch) => {
    try {
      await dispatch({ type: "SHOW_SIGNUPSUCCESS_POPUP", payload: data });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showForgotPasswordPopup = (data) => {
  return async (dispatch) => {
    try {
      await dispatch({ type: "SHOW_FORGOTPASSWORD_POPUP", payload: data });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showVerificationPopup = (data) => {
  return async (dispatch) => {
    try {
      await dispatch({ type: "SHOW_VERIFICATION_POPUP", payload: data });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getUserLoginStatusData = (data) => {
  return async (dispatch) => {
    try {
      await dispatch({ type: "GET_USERLOGINSTATUS_DATA", payload: data });
    } catch (error) {
      console.error(error);
    }
  };
};
export const updateAllRestaurantSort = (data) => {
  return async (dispatch) => {
    try {
      await dispatch({ type: "UPDATE_ALL_RESTAURANT_SORT", payload: data });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getFilterListBottomLocation = (data) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: "GET_FILTERLISTBOTTOMLOCATION_DATA",
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
