

  const initialState = {
    isLoading : false,
    errorMessage:'',
    selectedPreference:{
      allergendata:[],
      dietarydata:[],
      lifestyledata:[],
      featuredata:[],
      nearby:true,
      distance:0,
    },
    showMyPreferenceCheck:false,
  };
    
    const globalPreferenceFilterReducer = (state = initialState, { type, payload }) => {
      switch (type) {
          
        case "UPDATE_PREFERENCEFILTER_FORM":
            return  {
                ...state,
                isLoading: false,
                selectedPreference : {
                    ...state.selectedPreference,
                    [payload.key] : payload.value
                }
            };

        case "UPDATE_ALLPREFERENCEFILTER_FORM":
            return  {
                ...state,
                isLoading: false,
                selectedPreference : payload,
            };    

        case "GET_SHOWMYPREFERENCECHECKBOX_DATA":
          return {
            ...state,
            showMyPreferenceCheck: payload,
          };  

        default:
          return state;
      }
    };
    
    export default globalPreferenceFilterReducer;
    