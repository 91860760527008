

const initialState = {
    isLoading : false,
    allRestDishLoading : false,
    errorMessage:'',
    searchedRestaurantsList_Data:null,
    fourRestroData:[],
    fourDishData:[],
    searchedDishList_Data:null,
    fourMoreRustLoading : false,
    fourMoreDishLoading : false,

    };
    
    const restaurantSearchPageReducer = (state = initialState, { type, payload }) => {
      switch (type) {
        //getSearchedRestaurantsList
        case "GET_SEARCHEDRESTAURANTLIST_REQUEST":
            return {
                ...state,
                isLoading :true,
                fourRestroData:[]
            };
        case "GET_SEARCHEDRESTAURANTLIST_SUCCESS":
            return {
                ...state,
                isLoading:false,
                searchedRestaurantsList_Data:payload,
            };
    
        case "GET_SEARCHEDRESTAURANTLIST_FAILURE":
            return {
                ...state,
                isLoading:false,
                errorMessage:payload
            };

        //getFourRestaurantsData
        case "GET_FOURRESTAURANTLIST_REQUEST":
            return {
                ...state,
                fourMoreRustLoading :true,
            };
        case "GET_FOURRESTAURANTLIST_SUCCESS":
            return {
                ...state,
                fourMoreRustLoading:false,
                fourRestroData:[
                    ...state.fourRestroData,
                    ...payload.data
                ],
            };
    
        case "GET_FOURRESTAURANTLIST_FAILURE":
            return {
                ...state,
                fourMoreRustLoading:false,
                errorMessage:payload
            };    

        //getSearchedDishList
        case "GET_SEARCHEDDISHLIST_REQUEST":
            return {
                ...state,
                allRestDishLoading :true,
                fourDishData:[],
            };
        case "GET_SEARCHEDDISHLIST_SUCCESS":
            return {
                ...state,
                allRestDishLoading:false,
                searchedDishList_Data:payload,
            };
    
        case "GET_SEARCHEDDISHLIST_FAILURE":
            return {
                ...state,
                allRestDishLoading:false,
                errorMessage:payload
            };    

        //getFourDishData
        case "GET_FOURDISHLIST_REQUEST":
            return {
                ...state,
                fourMoreDishLoading :true,
            };
        case "GET_FOURDISHLIST_SUCCESS":
            return {
                ...state,
                fourMoreDishLoading:false,
                fourDishData:[
                    ...state.fourDishData,
                    ...payload.data
                ],
            };
    
        case "GET_FOURDISHLIST_FAILURE":
            return {
                ...state,
                fourMoreDishLoading:false,
                errorMessage:payload
            };    

        default:
          return state;
      }
    };
    
    export default restaurantSearchPageReducer;
    